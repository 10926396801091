@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@font-face {
  font-family: 'Montserrat';
  src: url('../public/Montserrat-VariableFont_wght.ttf');
}

body {
  margin: 0;
  font-family: 'Montserrat' !important
  /*-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
}

code {
  font-family: 'Montserrat'
}
